import React from 'react';
import { useNavigate } from 'react-router-dom';

function ConnectBtn() {
 const navigate = useNavigate(); 

  // Callback function for wallet connection
  const handleWalletConnect = () => {
    
    navigate('/wallets'); 
  };

  return (
    <div className="collapse navbar-collapse justify-content-end" id="navbarColor01">
      <i onClick={handleWalletConnect} className="fa fa-wallet"></i>
    </div>
  );
}

export default ConnectBtn;