import React from 'react';
import { useNavigate } from 'react-router-dom';

function Presentation() {
  const handleWalletConnect = () => {
    window.location.href = '/wallets';
  };

  return (
    <div class="presentation">
      <div class="p1_text_con">
        <h4 class="p1_highlight">⚡️ #1 CRYPTO RECOVERY TOOL OF 2023</h4>
        <h1 class="p1_title">Recover & Repair</h1>
        <p class="p1_subtitle">Expert Recovery Tools for Lost, Forgotten, <br /> or Compromised
          Digital Wallets</p>
        <a onClick={handleWalletConnect} class="p1_btn">Connect Wallet</a>
      </div>
      <div class="p1_spacer"></div>
      <img class="p1_img" src="../files/crypto-bg-img.png" alt="image" />
    </div>);
  
}

export default Presentation