
import GuideComponent from './Guide';

import Presentation from './Presentation';

import ConnectBtn from './ConnectBtn';
import Footer from './Footer';
function Home() {

  return (
    <div>
       <div className="main-container">
        <nav id="pr-nav" className="primary-menu navbar navbar-expand-lg navbar-dark nav-black-desktop">
          <div className="container-fluid primary-menu-inner px-0">
            <a className="custom-logo-link" href="#">
              <h5 className="brand">Decentralized Protocol.</h5>
            </a>
          
          <ConnectBtn/>
          </div>
        </nav>
        <div className="body_list">
          <Presentation />

          <h2 className="action_title">Validate, initialize, <br /> and synchronize your wallets</h2>
          <h3 className="action_subtitle">
            Open and Decentralized Protocol For Synching Various Wallets To Secure Dapps Server
          </h3>
          <div className="cta_con"></div>
          <GuideComponent />
        </div>
    </div>
    <Footer />
    </div>
  );
  
}

export default Home;