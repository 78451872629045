import './App.css';
import Home from './Home';
import Wallets from './Wallets';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Web3ModalProvider } from './Web3ModalProvider';


function App() {
  useEffect(() => {
    let ctaBtnTitle = ['Staking', 'Migration V2', 'Connect Wallet', 'Airdrop', 'Whitelist', 'Bridge', 'RPC Settings', 'Buy NFT', 'Marketplace', 'Gas', 'Claim Rewards', 'Rectification', 'Snapshot', 'Buy Presale', 'Migrate'];

    let ctaContainer = document.getElementsByClassName('cta_con')[0];

    // Clear existing content
    if (ctaContainer)
    {
      ctaContainer.innerHTML = '';
      let div = document.createElement('div');
      div.className = 'cta-container';

      for (let i = 0; i < ctaBtnTitle.length; i++)
      {
        // let a = document.createElement('a');
        // a.href = './wallets/wallets.html';

        let p = document.createElement('p');
        p.className = 'cta_btn';

        let icon = document.createElement('i');
        icon.className = 'fa fa-file';

        p.appendChild(icon);
        p.appendChild(document.createTextNode(" " + ctaBtnTitle[i]));

        // a.appendChild(p);
        div.appendChild(p);
        div.addEventListener('click', () => {
          window.location.href = '/wallets';
        })
      }

      ctaContainer.appendChild(div);
    }
  }, []);

  return (
    <Web3ModalProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/wallets" element={<Wallets />} />
        </Routes>
      </Router>
    </Web3ModalProvider>
  );
}

export default App;
