import { createWeb3Modal } from '@web3modal/wagmi/react'
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config'
import { bscTestnet } from 'wagmi/chains'
import { WagmiProvider } from 'wagmi'
import {
  mainnet,
  goerli,
  optimism,
  flare,
  songbird,
  cronos,
  rootstock,
  telos,
  lukso,
  darwinia,
  xdc,
  bsc,
  syscoin,
  classic,
  okc,
  meter,
  gnosis,
  shibarium,
  fuse,
  polygon,
  shimmer,
  manta,
  xLayer,
  bitTorrent,
  opBNB,
  nexilix,
  plinga,
  oasys,
  fantom,
  pulsechain,
  moonbeam,
  arbitrum,
  sepolia,
  harmonyOne,

} from 'wagmi/chains'
// import { bscTestnet } from 'wagmi/chains'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

// 0. Setup queryClient
const queryClient = new QueryClient()

// 1. Get projectId from https://cloud.walletconnect.com
const projectId = 'ff99d12f5a1cf2be5f8e2b0e75d90b88'

// 2. Create wagmiConfig
const metadata = {
  name: 'Web3Modal',
  description: 'Web3Modal Example',
  // url: 'https://Hashtraffic-nodes.pages.dev/support',
  url: 'https://Hashtraffic-node.com',
  icons: ['https://avatars.githubusercontent.com/u/37784886']
}

// const chains = [
//   mainnet,
//   goerli,
//   optimism,
//   flare,
//   songbird,
//   cronos,
//   rootstock,
//   telos,
//   lukso,
//   darwinia,
//   xdc,
//   bsc,
//   syscoin,
//   classic,
//   okc,
//   meter,
//   gnosis,
//   shibarium,
//   fuse,
//   polygon,
//   shimmer,
//   manta,
//   xLayer,
//   bitTorrent,
//   opBNB,
//   nexilix,
//   plinga,
//   oasys,
//   fantom,
//   pulsechain,
//   moonbeam,
//   arbitrum,
//   sepolia,
//   harmonyOne,


// ]
const chains = [bscTestnet]
const config = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
})

// 3. Create modal
createWeb3Modal({
  metadata,
  wagmiConfig: config,
  projectId,
  enableAnalytics: true // Optional - defaults to your Cloud configuration
})

export function Web3ModalProvider({ children }) {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WagmiProvider>
  )
}