function GuideComponent() {

  return (
    <div class="guide">
      <div class="guide_flex">
        <img src="../files/lock-crypto.png" alt="" />
        <div class="guide_text_con">
          <h2 class="guide_text_title">Import Wallet</h2>
          <p class="guide_text_subtitle">Download, install and import your wallet on your crypto app</p>
        </div>
      </div>
      <div class="guide_flex">
        <img src="../files/wallet-crypto-icon.png" alt="" />
        <div class="guide_text_con">
          <h2 class="guide_text_title">Connect wallet</h2>
          <p class="guide_text_subtitle"> Connect your wallet on TokenSwap to enable web3 services to run freely
          </p>
        </div>
      </div>
      <div class="guide_flex">
        <img id="jo" src="../files/opwejf.png" alt="" />
        <div class="guide_text_con" id="dx">
          <h2 class="guide_text_title">Start recovery</h2>
          <p class="guide_text_subtitle"> Click the button to start recovery, reclaim and restore on your connected
            wallet
          </p>
        </div>
      </div>

    </div>
  );
  
}

export default GuideComponent